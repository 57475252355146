<template>
  <div class="home">
    <van-nav-bar :title="$t('app.account')" :placeholder="true" fixed>
      <van-icon
        name="contact"
        slot="left"
        color="#fff"
        size=".46rem"
        @click="backtoperson"
      ></van-icon>
    </van-nav-bar>

    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="box">
        <van-collapse v-model="activeNames" :border="false">
          <Loading v-if="showLoading"></Loading>
          <van-collapse-item v-for="(item, index) in records" :key="index">
            <template #title>
              <div>
                <strong>{{ item.Login }}</strong>
              </div>
            </template>
            <template #right-icon>
              <div>
                <!-- <div style="float: right">
                  {{ $t("AccountInfo.moreDetail") }}
                </div> -->
                <van-icon name="more-o" color="#ccc" size="16" />
              </div>
            </template>
            <van-cell-group :border="false" class="home-account-detail">
              <van-cell
                :title="$t('AccountInfo.leverage')"
                :value="item.LEVERAGE"
                :border="false"
              />
              <van-cell
                :title="$t('AccountInfo.equity')"
                :value="formatPrice(item.EQUITY, 2)"
                :border="false"
              />
              <van-cell
                :title="$t('AccountInfo.balance')"
                :value="formatPrice(item.BALANCE, 2)"
                :border="false"
              />
              <van-cell
                :title="$t('AccountInfo.credit')"
                :value="formatPrice(item.CREDIT, 2)"
                :border="false"
              />
              <van-cell
                :title="$t('AccountInfo.freeMargin')"
                :value="formatPrice(item.MARGIN_FREE, 2)"
                :border="false"
              />
              <van-cell
                :title="$t('AccountInfo.regDate')"
                :value="
                  item.REGDATE.replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
                "
                :border="false"
              />
            </van-cell-group>

            <!-- <van-row>
              <van-col span="12"
                >{{ $t("AccountInfo.leverage") }}:<span class="itemright">{{
                  item.LEVERAGE
                }}</span></van-col
              >
              <van-col span="12"
                >{{ $t("AccountInfo.equity") }}:<span class="itemright">{{
                  formatPrice(item.EQUITY, 2)
                }}</span></van-col
              >
              <van-col span="12"
                >{{ $t("AccountInfo.balance") }}:<span class="itemright">{{
                  formatPrice(item.BALANCE, 2)
                }}</span></van-col
              >
              <van-col span="12"
                >{{ $t("AccountInfo.credit") }}:<span class="itemright">{{
                  formatPrice(item.CREDIT, 2)
                }}</span></van-col
              >
              <van-col span="12"
                >{{ $t("AccountInfo.freeMargin") }}:<span class="itemright">{{
                  formatPrice(item.MARGIN_FREE, 2)
                }}</span></van-col
              >
              <van-col span="24"
                >{{ $t("AccountInfo.regDate") }}:<span class="itemright">{{
                  item.REGDATE.replace(/T/g, " ").replace(/\.[\d]{3}Z/, "")
                }}</span></van-col
              >
            </van-row> -->
          </van-collapse-item>
        </van-collapse>
        <div
          style="margin-bottom: 180px; text-align: center; padding: 25px 0px"
        >
          <span v-if="records.length < total" @click="nextpage()">{{
            $t("app.clickLoadMore")
          }}</span>
          <span v-else>{{ $t("app.noMoreData") }}</span>
        </div>
      </div>
    </van-pull-refresh>

    <div class="van-safe-area-bottom"></div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import { getHome } from "@/api";
    import { forPrice } from "@/utils/commons";
    import { Notify, Dialog } from 'vant';
export default {
  name: "Home",
  components: {
    NavBar,
    Loading: () => import("@/components/crm/Loading"),
  },
  data() {
    return {
      pageIndex: 1,
      pageSize: 12,
      records: [],
      total: 0,
      color: "green",
      activeNames: [],
      isLoading: false,
      showLoading: true,
    };
  },
  mounted() {
    this.loadPageData();
  },
  watch: {},
  created() {},
  methods: {
    loadPageData() {
      let param = {
        offset: this.pageIndex,
        pageSize: this.pageSize,
      };
      getHome(param).then((res) => {
        this.isLoading = false;
        if ((res.code = 200)) {
          this.showLoading = false;
          if (this.pageIndex == 1) {
            this.records = res.msg.rows;
          } else {
            res.msg.rows.forEach((element) => {
              this.records.push(element);
            });
          }
          this.total = res.msg.total;
        } else {
            Notify({ type: 'danger', message: this.$t(res.msg) });
        }
      });
    },
    nextpage() {
      this.pageIndex++;
      this.loadPageData();
    },
    formatPrice(value, len) {
      return forPrice(value, len);
    },
    onRefresh() {
      this.records = [];
      this.loadPageData();
    },
    backtoperson() {
      this.$router.push("/member");
    },
  },
};
</script>

<style scoped>
.box {
  overflow-y: auto;
  min-height: 100vh;
  background-color: #f7f8fa;
}
.van-nav-bar__placeholder {
  height: 66px;
}
.home >>> .van-nav-bar {
  background-color: #13b5b1;
  padding: 20px 0 0 0;
}
.home >>> .van-nav-bar__title {
  color: #fff;
}
.fa {
  color: #25a4bb;
}

.dropdown-menu-option-active-color {
  color: #25a4bb;
}

.itemright {
  float: right;
  padding-right: 10px;
}
.home-account-detail .van-cell {
  padding: 0 10px;
}
.van-collapse {
  margin: 14px;
  border-radius: 8px;
  overflow: hidden;
}
</style>
